import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import { Box, Center, Image } from '@chakra-ui/react'
import classNames from 'classnames'
import Link from 'next/link'
import React from 'react'

type Props = {
  id: number
  size?: string
  lazyload?: boolean
  href?: string
}

export const UkiyoePreview = ({ id, href, size = '128px', lazyload = false }: Props) => {
  const linkHref = href || `/details/${id}`
  const className = classNames({
    pixelated: true,
    lazyload,
  })

  const src = lazyload ? {} : { src: `/images/ukiyoe/b/${id}.png` }

  return (
    <Box cursor="pointer">
      <Center>
        <Link href={linkHref} prefetch={false}>
          <a>
            <Image
              bg="#677f75"
              data-src={`/images/ukiyoe/b/${id}.png`}
              fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNMry/9DwAFDAJc2KCJiQAAAABJRU5ErkJggg=="
              fit="contain"
              className={className}
              w={size}
              h={size}
              htmlWidth={size}
              htmlHeight={size}
              alt={id.toString()}
              {...src}
            />
          </a>
        </Link>
      </Center>
    </Box>
  )
}
